
const key = 'dl_user_login_expire_time';

export function setExpireTime() {
    if (isLogin()) {
        localStorage.setItem(key, new Date().getTime() + 3600 * 2 * 1000);
    }
}

export function checkValidTime() {
    let expireTime = localStorage.getItem(key);

    if (expireTime < new Date().getTime() && isLogin()) {
        logout();
        location.href = "/login";
    }

    if (!isLogin() && ['/'].includes(window.location.pathname)) {
        location.href = "/login";
    }

    setExpireTime();
}

export function logout() {
    localStorage.setItem('api_token', '');
    localStorage.setItem('user_info', '');
    localStorage.setItem('is_login', 0);
}

export function isLogin() {
    return parseInt(localStorage.getItem('is_login')) === 1;
}
