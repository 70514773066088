<template>
  <div id="header" class="fs-26 fw-300" v-show="show_flag === 1">
    <span class="m-20">首页</span>
    <span class="m-20">人物</span>
  </div>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: 'Header',
  components: {
  },
  watch: {
    $route: {
      handler: function (route) {
        this.checkView(route);
      },
      immediate: true
    }
  },
  methods: {
    checkView(route) {
      switch (route.path) {
        case '/login':
        case '/forgot':
          this.show_flag = 2;//2表示不显示导航
          break;
        default:
          if (loginUtil.isLogin()) {
            this.show_flag = 1;
          } else {
            this.show_flag = 0;
          }
      }

      console.log(this.show_flag)
    }
  },
}
</script>

<style scoped>
#header {
  display: flex;
  padding:0 10px;
}
</style>