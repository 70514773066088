<template>
  <div id="app">
    <Header></Header>
    <Body></Body>
  </div>
</template>

<script>
import Body from '@/views/Body'
import Header from '@/views/Header'


export default {
  name: 'App',
  components: {
    Body,
    Header
  }
}
</script>

<style>
#app {
  min-height: 100vh;
  color:#2c3e50;
}
</style>
