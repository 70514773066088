const Index = () => import('@/views/Index.vue');
const People = () => import('@/views/people/Index.vue');

const Login = () => import('@/views/user/Login.vue');
const SignUp = () => import('@/views/user/SignUp.vue');
const JobSet = () => import('@/views/JobSet.vue');



export default [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: 'index'
        }
    },
    {
        path: '/people',
        name: 'people',
        component: People,
        meta: {
            title: 'people'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/signUp',
        name: 'signUp',
        component: SignUp,
        meta: {
            title: 'Sign Up'
        }
    },
    {
        path: '/jobSet',
        name: 'jobSet',
        component: JobSet,
        meta: {
            title: 'Job Set'
        }
    }
]
