<template>
  <div id="body">
    <router-view/>
  </div>
</template>
  
<script>
  export default {
    name: "Body",
    components: {
    }
  }
</script>
  
<style scoped>
  
</style>
  