import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import '../theme/index.css';

import router from '@/router';
import http from '@/plugins/http'
import md5 from 'md5'

Vue.use(ElementUI);

//注册到vue原型上
Vue.prototype.axios = http;
Vue.prototype.md5 = md5;

import "@/assets/css/common.css";
import "@/assets/css/job.css";
import * as loginUtil from '@/utils/login'


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title
  }

  loginUtil.checkValidTime();

  next();
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');